@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@300;400;600&display=swap');

body,
#root {
  font-family: 'Noto Sans Thai', sans-serif;
  overflow-x: hidden;
  height: 100%;
  width: auto;
}

html {
  scroll-behavior: smooth;
}